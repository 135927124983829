<template>
  <div ref="numBox" class="number-box" style="opacity:0">
    <p v-for="(item, index) in computeNumber" :key="index" :style="{ color: `${colors}`, padding: `${isNaN(item) ? '0 7px' : ''}` }" class="box-item">
      <i v-if="!isNaN(item)">
        <span ref="numberItem">0<br />1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9</span>
      </i>
      <i v-else
        ><b>{{ item }}</b></i
      >
    </p>
  </div>
</template>
<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    numbers: {
      type: String
    },
    // eslint-disable-next-line vue/require-default-prop
    colors: {
      type: String
    }
  },
  data() {
    return {
      maxLen: 6, // 数字最大值
      computeNumber: [],
      timeTicket: null,
      number: '000'
    }
  },
  mounted() {
    // this.increaseNumber();
  },
  destroyed() {
    window.clearTimeout(this.timeTicket)
    this.timeTicket = null
  },
  created() {
    this.refresh()
    setTimeout(() => {
      this.number = this.numbers
      this.$refs.numBox.style.opacity = 1
      this.refresh()
    }, 1000)
  },
  methods: {
    // 补0 操作 返回数组 num 被操作数 n 总长度
    prefixZero(num, n) {
      return (Array(n).join(0) + num).slice(n - 1).split('')
    },
    // 获得随机数
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    // 定时增长数字
    // increaseNumber () {
    //     // this.refresh()
    //     // this.timeTicket = setTimeout(this.increaseNumber,5000);
    // },
    // 定时刷新数据
    refresh() {
      // this.number=this.getRandomNumber(0,999999);
      this.computeNumber = this.prefixZero(this.number, this.maxLen)
      this.$nextTick(() => this.setNumberTransform())
    },
    // 设置每一位数字的偏移
    setNumberTransform() {
      // let numberItems = this.$refs.numberItem
      // for (let index = 0; index < numberItems.length; index++) {
      //     let elem = numberItems[index]
      //     elem.style.transform = `translate(-50%, -${Number(this.computeNumber[index] * 10)}%)`;
      //     console.log(elem)
      // }
      let numberItems = this.$refs.numberItem
      let numberArr = this.computeNumber.filter((item) => !isNaN(item))
      for (let index = 0; index < numberItems.length; index++) {
        let elem = numberItems[index]
        elem.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`
      }
    }
  }
}
</script>
<style scoped lang="scss">
.number-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 7% auto 0;
}
p {
  /* flex: 1; */
  height: 40px;
  line-height: 60px;
  /* background: #2a3242; */
  /* margin: 0 26px 0 0; */
  color: #fff;
  text-align: center;
  border-radius: 12px;
  padding: 0 12px;
  display: inline-block;
  font-size: 62px;
  position: relative;
  /* writing-mode: vertical-lr; */
  /* text-orientation: upright; */
  overflow: hidden;
}
p:last-child {
  margin: 0px;
}
b {
  /* color:#9186e0; */
  font-size: 40px;
  font-weight: normal;
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 1s;
  /* letter-spacing: 10px; */
}
span {
  /* color:#9186e0; */
  font-size: 40px;
  font-family: 'Arial';
  font-weight: normal;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 1s;
  /* letter-spacing: 10px; */
}
</style>
