import request from './services/axios.js'

// 内容聚合相关接口
// 获取优先出版论文
export function GetFirstArticle(params) {
  return request({
    params,
    url: '/vas/mag/getFirstArticle',
    method: 'get'
  })
}
// 获取整期发表期刊
export function GetIssues(params) {
  return request({
    params,
    url: '/vas/mag/getIssues',
    method: 'get'
  })
}

// 获取整期发表文章
export function GetIssueArticles(params) {
  return request({
    params,
    url: '/vas/mag/getIssueArticles',
    method: 'get'
  })
}
// 获取内容聚合范围
export function GetTextRange(params) {
  return request({
    params,
    url: '/vas/mag/getTextRange',
    method: 'get'
  })
}
// 内容聚合范围查询
export function GetTextContent(params) {
  return request({
    params,
    url: '/vas/mag/getTextContent',
    method: 'get'
  })
}
// 设置内容聚合范围
export function SetTextRule(params) {
  return request({
    data:params,
    url: '/vas/mag/setTextRule',
    method: 'post'
  })
}
