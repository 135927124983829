import request from './services/axios.js'

// 期刊聚类相关接口
// 期刊聚类引证统计
export function GetCitationCluster(params) {
  return request({
    params,
    url: '/vas/statistics/citationCluster',
    method: 'get'
  })
}
// 期刊聚类引用统计
export function GetReferenceCluster(params) {
  return request({
    params,
    url: '/vas/statistics/referenceCluster',
    method: 'get'
  })
}

// 期刊聚类刊物列表
export function GetGetMags(params) {
  return request({
    params,
    url: '/vas/statistics/getMags',
    method: 'get'
  })
}

// 期刊聚类刊物引证统计
export function GetClusterMagCitation(params) {
  return request({
    params,
    url: '/vas/statistics/clusterMagCitation',
    method: 'get'
  })
}

// 期刊聚类刊物引用统计
export function GetClusterMagReference(params) {
  return request({
    params,
    url: '/vas/statistics/clusterMagReference',
    method: 'get'
  })
}

// 参考文献列表获取
export function GetReferenceList(params) {
  return request({
    params,
    url: '/vas/statistics/referenceList',
    method: 'get'
  })
}
