import request from './services/axios.js'

// 论文详情相关接口
// 获取论文元数据信息
export function GetArticleInfo(params) {
  return request({
    params,
    url: '/vas/article/getArticleInfo',
    method: 'get'
  })
}
// 获取论文引证分析
export function CitationArticlesAnalysis(params) {
  return request({
    params,
    url: '/vas/article/citationArticlesAnalysis',
    method: 'get'
  })
}
// 获取论文引用分析
export function ReferenceArticlesAnalysis(params) {
  return request({
    params,
    url: '/vas/article/referenceArticlesAnalysis',
    method: 'get'
  })
}
// 论文引证列表
export function GetSingleCitationArticlesList(params) {
  return request({
    params,
    url: '/vas/article/getSingleCitationArticlesList',
    method: 'get'
  })
}
// 论文引用列表
export function GetSingleReferenceArticlesList(params) {
  return request({
    params,
    url: '/vas/article/getSingleReferenceArticlesList',
    method: 'get'
  })
}
